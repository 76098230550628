<template>
  <div>
    <div class="text-right pb-2"></div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-start py-1">
        <b-col md="8" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack">
            Teacher as Head of department
          </h3>
          <p class="font-weight-bold text-colorGrey">
            Your all Teachers in Department display here.
          </p>
        </b-col>
        <b-col md="4" class="px-3 py-1 text-right">
          <b-button
            type="submit"
            variant="colorBlue"
            rounded
            class="align-items-center"
            @click="createDepartmentHead"
          >
            <feather-icon icon="PlusIcon" class="mr-50 text-colorWhite" />
            <span class="text-colorWhite">Head of Department </span>
          </b-button>
        </b-col>
      </b-row>
      <b-card class="mx-2" style="border-radius: 20px">
        <b-table
          stacked="md"
          :fields="fieldsDepartmentHead"
          :items="DepartmentHead"
          thead-class="tableHeadColor"
          small
          rounded
        >
          <template #head()="data">
            <div class="py-50">
              <span class="font-weight-bolder text-colorBlack">{{
                data.label
              }}</span>
            </div>
          </template>

          <template #cell(DepartmentHead)="row">
            <div class="py-1">
              <span
                class="font-weight-bolder text-colorBlack m-0"
                style="font-size: 0.8rem"
              >
                <img
                  src="@/assets/images/icons/uniHead/department/user.svg"
                  img-fluid
                  class="mx-1 cursor-pointer"
                  width="30px"
                  height="30px"
                />
                {{ row.item.DepartmentHead ? row.item.DepartmentHead : "" }}
              </span>
            </div>
          </template>

          <template #cell(DateTime)="row">
            <div class="py-1">
              <span
                class="font-weight-bolder text-colorBlack m-0"
                style="font-size: 0.8rem"
              >
                {{ row.item.DateTime ? row.item.DateTime : "" }}
              </span>
            </div>
          </template>

          <template #cell(manage)="">
            <div class="text-right">
              <img
                src="@/assets/images/icons/instructor/assignment/Delete.svg"
                img-fluid
                class="mx-1 cursor-pointer"
                width="30px"
                height="30px"
              />

              <img
                src="@/assets/images/icons/instructor/assignment/Edit.svg"
                img-fluid
                width="30px"
                height="28px"
                class="cursor-pointer"
                @click="editDepartmentHead"
              />
            </div>
          </template>
        </b-table>
        <b-pagination
          size="md"
          :total-rows="totalItems"
          v-model="currentPage"
          :per-page="perPage"
        ></b-pagination>
      </b-card>
    </div>
    <CreateDepartmentHeadModal
      @modalClosed="onModalClosed"
      :key="`create-${createDepartmentHeadModalCount}`"
    />
    <EditDepartmentHeadModal
      @modalClosed="onModalClosed"
      :key="`edit-${editDepartmentHeadModalCount}`"
    />
  </div>
</template>

<script>
import CreateDepartmentHeadModal from "@/components/uniHead/departments/departmentHead/CreateDepartmentHeadModal.vue";
import EditDepartmentHeadModal from "@/components/uniHead/departments/departmentHead/EditDepartmentHeadModal.vue";
export default {
  components: {
    CreateDepartmentHeadModal,
    EditDepartmentHeadModal,
  },
  data() {
    return {
      createDepartmentHeadModalCount: 0,
      editDepartmentHeadModalCount: 0,
      fieldsDepartmentHead: [
        { key: "DepartmentHead", label: "Department Head" },
        { key: "DateTime", label: "Date & Time" },
        { key: "manage", label: "" },
      ],
      DepartmentHead: [
        {
          DepartmentHead: " Flores, Juanita",
          DateTime: "24 Sep, 2020 02:00 pm",
        },
        {
          DepartmentHead: " Nguyen, Shane",
          DateTime: "24 Sep, 2020 02:00 pm",
        },
        {
          DepartmentHead: " Pintér Beatrix",
          DateTime: "24 Sep, 2020 02:00 pm",
        },
      ],
      max: 20,
    };
  },
  methods: {
    createDepartmentHead() {
      this.CreateDepartmentHeadModal += 1;
      this.$nextTick(() => {
        this.$bvModal.show("create-department-head-modal");
      });
    },
    editDepartmentHead() {
      this.EditDepartmentHeadModal += 1;
      this.$nextTick(() => {
        this.$bvModal.show("edit-department-head-modal");
      });
    },
    async onModalClosed() {},
  },
};
</script>

<style></style>
