<template>
  <b-modal
    id="create-department-head-modal"
    centered
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    hide-footer
  >
    <template #modal-title>
      <h2 class="m-0 text-colorBlack">Add Head of Department</h2>
    </template>
    <b-form>
      <b-form-row class="py-1">
        <b-col>
          <span class="font-weight-bolder text-colorBlack"
            >Head Department</span
          >
          <b-form-group class="mb-0">
            <v-select
              :options="['Math', 'English']"
              placeholder="Head Department"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row class="py-1">
        <b-col>
          <span class="font-weight-bolder text-colorBlack">
            Select Department
          </span>
          <b-form-group class="mb-0">
            <v-select
              :options="['Math', 'English']"
              placeholder=" Department"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>

    <b-form-group>
      <b-button
        block
        type="submit"
        variant="colorBlue"
        style="margin-top: 400px"
        pill
      >
        Add
      </b-button>
    </b-form-group>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  props: {},
  mixins: [util],
  data() {
    return {
      departmentName: "",
    };
  },
  async mounted() {},
  methods: {
    ...mapActions({}),
  },
  computed: {},
};
</script>

<style scoped></style>
